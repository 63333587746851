[data-sparta-container] {
  .headline-xxlarge {
    @include headline-xxlarge();
  }

    .headline-xlarge {
    @include headline-xlarge();
  }

  .headline-large {
    @include headline-large();
  }

  .headline-med {
    @include headline-med();
  }

  .headline-small {
    @include headline-small();
  }

  .headline-xsmall {
    @include headline-xsmall();
  }

  .body-medium {
    @include body-medium();
  }

  .body-small {
    @include body-small();
  }

  .body-xsmall {
    @include body-xsmall();
  }

  .data-xlarge {
    @include data-xlarge();
  }

  .data-xlarge-bold {
    @include data-xlarge-bold();
  }

  .data-xlarge-medium {
    @include data-xlarge-medium();
  }

  .data-large {
    @include data-large();
  }

  .data-large-bold {
    @include data-large-bold();
  }

  .data-large-medium {
    @include data-large-medium();
  }

  .data {
    @include data();
  }

  .data-bold {
    @include data-bold();
  }

  .data-medium {
    @include data-medium();
  }

  .data-small {
    @include data-small();
  }

  .data-small-bold {
    @include data-small-bold();
  }

  .data-small-medium {
    @include data-small-medium();
  }

  .data-xsmall {
    @include data-xsmall();
  }

  .data-xsmall-bold {
    @include data-xsmall-bold();
  }

  .data-xsmall-medium {
    @include data-xsmall-medium();
  }

  .label-medium {
    @include label-medium();
  }

  .label-small {
    @include label-small();
  }

  .label-xsmall {
    @include label-xsmall();
  }
}     
