






[data-sparta-container] {
    .spa-screen-overlay {
        @include screen-overlay;
    }
}

[data-sparta-container].spa-screen-overlay {
    @include screen-overlay($position: fixed);
    z-index: $layer-top;
}