.shadow-top {
  @include shadow-top;
}
.shadow-bottom {
  @include shadow-bottom;
}
.shadow-dropdown {
  @include shadow-dropdown;
}
.shadow-left {
  @include shadow-left;
}
.shadow-default {
  @include shadow-default;
}
.shadow-overlay {
  @include shadow-overlay;
}