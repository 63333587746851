

$btn-base: 'spa-btn';


$btn-font-family: $roboto-medium-family, $cnx-font-fallbacks;
$btn-font-weight: 500;
$btn-text-align: center;
$btn-text-align-vertical: middle;
$btn-line-height: 1;
$btn-small--line-height: 16px;
$btn-large--line-height: 24px;
$btn-xsmall--font-size: 12px;
$btn-small--font-size: 14px;
$btn-medium--font-size: 20px;
$btn-large--font-size: 28px;
$btn-label--font-size: 16px;
$circle-btn-font-family: $roboto-family, $cnx-font-fallbacks;



$btn--padding-vertical: $spacing-8;
$btn-xsmall--padding-vertical: $spacing-4;
$btn-xsmall--padding-horizontal: $spacing-12;
$btn-small--padding-horizontal: $spacing-16;
$btn-medium--padding-horizontal: $spacing-24;
$btn-large--padding-horizontal: $spacing-32;
$btn-xsmall--padding: $btn-xsmall--padding-vertical $btn-xsmall--padding-horizontal;
$btn-small--padding: $btn--padding-vertical $btn-small--padding-horizontal;
$btn-medium--padding: $btn--padding-vertical $btn-medium--padding-horizontal;
$btn-large--padding: $btn--padding-vertical $btn-large--padding-horizontal;







$btn-xsmall--min-width: 60px;
$btn-xsmall--height: 24px;
$btn-small--height: 32px;
$btn-medium--height: 44px;
$btn-large--height: 56px;


$btn-bordered--border-width: 1px;
$btn-bordered--hover-border-width: 2px;
$btn-bordered--hover-inner-border-width: 1px;
$btn-xsmall--border-radius: 18px;
$btn-small--border-radius: 16px;
$btn-medium--border-radius: 22px;
$btn-large--border-radius: 28px;


$btn-interaction--text-color: $white; 
$btn-interaction--bg-color: $color-royal-one;


$btn-hover--border-color: $color-royal-one;


$btn-focus--border-color: transparent;
$btn-focus--box-shadow: inset 0 0 0 2px $white;


$btn-active--box-shadow: inset 0 4px 0 0 $color-brand-secondary;


$btn-disabled--text-color: $color-gray; 
$btn-disabled--bg-color: $color-gray-s10;
$btn-disabled--link--bg-color: transparent;


$me-contrast-button-bg-color: $bank-primary-red;
$me-contrast-button-text-color: $bank-white;
$me-contrast-button-hover-bg-color: $bank-regal-red;
$me-contrast-button-hover-text-color: $bank-white;


$btn-text-icon-font-family: $roboto-family, $cnx-font-fallbacks;
$btn-text-icon-small--font-size: $type-size-14;
$btn-text-icon-large--font-size: $type-size-16;
$btn-text-icon-small--line-height: $line-height-16;
$btn-text-icon-large--line-height: $line-height-24;
$btn-text-icon-small--height: $btn-small--height;
$btn-text-icon-large--height: $btn-medium--height;
$btn-text-icon-radius: $spacing-4;
$btn-text-icon-active-box-shadow:  inset 0 2px 0 $color-brand-secondary;
$btn-text-icon-selected-border-width: $spacing-2;
$btn-text-icon-selected-hover-border-width: $spacing-2;
$btn-text-icon-selected-box-shadow: inset 0 0 0 $spacing-1 $white;


$web-button-primary: (
  text-color: $color-brand-tertiary,
  bg-color: $color-brand-secondary,
  border-color: $color-brand-secondary,
  hover-text-color: $color-brand-tertiary,
  hover-bg-color: $color-royal-one,
  border-width: $btn-bordered--border-width,
  hover-border-color: $color-royal-one,
  hover-border-width: $btn-bordered--hover-border-width,
  hover-inner-border-color: $color-brand-tertiary,
  hover-inner-border-width: $btn-bordered--hover-inner-border-width,
  active-box-shadow-color: $btn-active--box-shadow,
  focus-border-color: $btn-hover--border-color,
  focus-box-shadow-color: $btn-focus--box-shadow,
  active-focus-outline: none
);

$web-button-secondary: (
  text-color: $color-brand-secondary,
  bg-color: $color-brand-tertiary,
  border-color: $color-brand-secondary,
  hover-text-color: $color-brand-tertiary,
  hover-bg-color:  $color-royal-one,
  border-width: $btn-bordered--border-width,
  hover-border-color: $color-royal-one,
  hover-border-width: $btn-bordered--hover-border-width,
  hover-inner-border-color: $color-brand-tertiary,
  hover-inner-border-width: $btn-bordered--hover-inner-border-width,
  active-box-shadow-color: $btn-active--box-shadow,
  focus-border-color: $btn-hover--border-color,
  focus-box-shadow-color: $btn-focus--box-shadow,
  active-focus-outline: none
);

$web-button-tertiary: (
  text-color: $color-royal-one,
  bg-color: transparent,
  border-color: transparent,
  hover-text-color: $color-brand-tertiary,
  hover-bg-color: $color-royal-one,
  border-width: $btn-bordered--border-width,
  hover-border-color: $color-royal-one,
  hover-border-width: $btn-bordered--hover-border-width,
  hover-inner-border-color: $color-brand-tertiary,
  hover-inner-border-width: $btn-bordered--hover-inner-border-width,
  active-box-shadow-color: $btn-active--box-shadow,
  focus-border-color: $btn-hover--border-color,
  focus-box-shadow-color: $btn-focus--box-shadow,
  active-focus-outline: none
);

$btn-skin--disabled: (
  text-color: $btn-disabled--text-color,
  bg-color: $btn-disabled--bg-color,
  border-color: $btn-disabled--bg-color,
  hover-text-color: $btn-disabled--text-color,
  hover-bg-color: $btn-disabled--bg-color,
  border-width: $btn-bordered--border-width,
  focus-border-color: $btn-hover--border-color,
  active-box-shadow-color: $btn-active--box-shadow,
  focus-box-shadow-color: $btn-focus--box-shadow,
  active-focus-outline: none
);

$btn-skin--link: (
  text-color: $bank-medium-blue,
  bg-color: transparent,
  hover-text-color: $bank-dark-blue,
  hover-bg-color: transparent,
  border-color: transparent,
  border-width: $btn-bordered--border-width,
  hover-border-color: transparent,
  focus-border-color: $btn-hover--border-color,
  active-box-shadow-color: none,
  focus-box-shadow-color: none,
  active-focus-outline: none
);

$btn-text-icon: (
  text-color: $color-black,
  bg-color: $white,
  border-color: transparent,
  border-width: $btn-bordered--border-width,
  icon-color: $color-black,
  disabled-text-color: $color-gray-s70,
  disabled-bg-color: transparent,
  disabled-border: 0 none,
  hover-text-color: $color-royal-one,
  hover-bg-color: $color-royal-one-s10,
  hover-icon-color: $color-royal-one,
  hover-border-color: $color-royal-one,
  hover-border-width: $btn-bordered--border-width,
  focus-text-color: $color-black,
  focus-bg-color: $white,
  focus-icon-color: $color-royal-one,
  focus-border-color: $color-royal-one,
  focus-border-width: $btn-bordered--border-width,
  focus-box-shadow: 0 none,
  active-text-color: $white,
  active-bg-color: $color-royal-one,
  active-icon-color: $color-royal-one,
  active-border-color: $color-royal-one,
  active-border-width: $btn-bordered--border-width,
  active-box-shadow: $btn-text-icon-active-box-shadow
);

$btn-text-icon-selected: (
  text-color: $white,
  bg-color: $color-royal-one,
  border-color: transparent,
  border-width: $btn-bordered--border-width,
  icon-color: $color-black,
  hover-text-color: $color-royal-one,
  hover-bg-color: $color-royal-one-s10,
  hover-icon-color: $color-royal-one,
  hover-border-color: $color-royal-one,
  hover-border-width: $btn-text-icon-selected-hover-border-width,
  focus-text-color: $white,
  focus-bg-color:  $color-royal-one,
  focus-icon-color: $white,
  focus-border-color: $color-royal-one,
  focus-border-width: $btn-bordered--border-width,
  focus-box-shadow: $btn-text-icon-selected-box-shadow
);




























































