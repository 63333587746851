






    [data-sparta-container] {
      &.flex-grid-nest {
          width: 100%;
          height: 100%;

          .spa-layout-container {
              min-height: 100%;
              background: #fff;
              padding-left: 0;
              padding-right: 0;
              margin: 0 auto;
          }

          .head-row {
              > .row {
                  width: 100% !important;
                  max-width: 100% !important;
                  padding-left: 0 !important;
                  padding-right: 0 !important;

                  > .column,
                  > .columns {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                  }
              }

              > .spa-uncollapse-large {
                  max-width: 100% !important;

                  > .large-12 {
                      width: 100% !important;
                      margin: 0;
                      padding: 0;
                  }
              }
          }

          .foot-row {
              > .row {
                  width: 100% !important;
                  max-width: 100% !important;
                  padding-left: 0 !important;
                  padding-right: 0 !important;

                  > .column,
                  > .columns {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                  }
              }

              > .spa-uncollapse-large {
                  max-width: 100% !important;

                  > .large-12 {
                      width: 100% !important;
                      margin: 0;
                      padding: 0;
                  }
              }
          }
      }
    }
