






        @import '_config.scss';

        [data-sparta-container] {
          .plus-minus {
              &:before, &:after {
                  content: "";
                  width: $plus-minus-size;
                  height: $plus-minus-size;
                  position: absolute;
                  top: $plus-minus-shift;
                  left: 0;
                  border-bottom: $plus-minus-line;
                  margin-top: #{(0 - $plus-minus-shift)}px;
              }

              &:after {
                  border-bottom: 0;
                  border-left: $plus-minus-line;
                  margin: 0 #{(0 - $plus-minus-shift)}px 0 #{$plus-minus-shift}px;
                  transition: clip 0.3s linear;
                  clip: rect(0, $plus-minus-size, $plus-minus-size, 0);
              }

              &.minus:after {
                  clip: rect(#{$plus-minus-half}px, #{$plus-minus-size}, #{$plus-minus-half}, 0);
              }
          }
        }
