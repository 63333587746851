



[data-sparta-container] {
  .full-width-row {
    .picture-fader {
      &:before,
      &:after {
        z-index:1;
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 100%;
        box-sizing:border-box;
      }
      &:before {
        background-image: linear-gradient(to right,
          hsl(0, 0%, 100%) 0%,
          hsla(0, 0%, 100%, 0.738) 19%,
          hsla(0, 0%, 100%, 0.541) 34%,
          hsla(0, 0%, 100%, 0.382) 47%,
          hsla(0, 0%, 100%, 0.278) 56.5%,
          hsla(0, 0%, 100%, 0.194) 65%,
          hsla(0, 0%, 100%, 0.126) 73%,
          hsla(0, 0%, 100%, 0.075) 80.2%,
          hsla(0, 0%, 100%, 0.042) 86.1%,
          hsla(0, 0%, 100%, 0.021) 91%,
          hsla(0, 0%, 100%, 0.008) 95.2%,
          hsla(0, 0%, 100%, 0.002) 98.2%,
          hsla(0, 0%, 100%, 0) 100%);
        margin-left: -932px;
      }
      &:after {
        background-image: linear-gradient(to left,
          hsl(0, 0%, 100%) 0%,
          hsla(0, 0%, 100%, 0.738) 19%,
          hsla(0, 0%, 100%, 0.541) 34%,
          hsla(0, 0%, 100%, 0.382) 47%,
          hsla(0, 0%, 100%, 0.278) 56.5%,
          hsla(0, 0%, 100%, 0.194) 65%,
          hsla(0, 0%, 100%, 0.126) 73%,
          hsla(0, 0%, 100%, 0.075) 80.2%,
          hsla(0, 0%, 100%, 0.042) 86.1%,
          hsla(0, 0%, 100%, 0.021) 91%,
          hsla(0, 0%, 100%, 0.008) 95.2%,
          hsla(0, 0%, 100%, 0.002) 98.2%,
          hsla(0, 0%, 100%, 0) 100%);
        margin-left: 932px;
      }
    }
  }
}
