

  [data-sparta-container] {
    .spa-right-rail-module {
        width: 100%;
        height: auto;
        padding: 10px 15px;
        margin-bottom: 15px;
        color: #666;
        font: normal normal normal 14px/18px Arial, Helvetica, sans-serif;

        &--primary {
            background: $brand-bright-red;
            color: $white;
            padding: 20px 15px;
        }

        &--secondary {
            background: #ECE6DD;
        }

        &--outline {
            border: 1px solid #ECE6DD;
        }

        &-heading {
            font-size: 22px;
            margin-top: 0;
            margin-bottom: 20px;
        }

        &--primary .spa-right-rail-module-heading {
            font-size: 24px;
            color: #fff;
        }

        p,
        ul,
        ol,
        a {
            font: normal normal normal 14px/18px Arial, Helvetica, sans-serif;
            margin-bottom: 10px;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 10px;
        }
    }
  }
