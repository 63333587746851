






        $arrow-mid-gray:  $bank-mid-gray;
        $arrow-light-gray: $bank-light-gray;
        $arrow-blue: $bank-primary-blue;
        $arrow-dark-blue: $bank-dark-blue;
        $arrow-red: $bank-primary-red;
        $arrow-disabled: $bank-light-gray-tertiary;

        $arrow-gray-skin-color: $arrow-mid-gray;
        $arrow-gray-skin-hover-color: $arrow-light-gray;

        $arrow-blue-skin-color: $arrow-blue;
        $arrow-blue-skin-hover-color: $arrow-dark-blue;

        $arrow-red-skin-color: $arrow-red;
        $arrow-red-skin-hover-color: $arrow-light-gray;

        $arrow-disabled-skin-color: $arrow-disabled;
        $arrow-disabled-skin-hover-color: $arrow-disabled;

        $arrow-position-x: 7px;

        $arrow-before-after-small-width: 2px;
        $arrow-before-after-small-height: 8px;

        $arrow-before-after-large-width: 5px;
        $arrow-before-after-large-height: 19px;

        $arrow-before-after-standard-rotation: 45deg;

        $arrow-learn-more-rotation: 90deg;

        $arrow-learn-more-translate-x-offset: -50%;

        $arrow-left-before-top-small: 0;
        $arrow-left-after-top-small: 6.5px;

        $arrow-left-before-top-large: 0;
        $arrow-left-after-top-large: 10px;

        $arrow-left-before-rotate: $arrow-before-after-standard-rotation;
        $arrow-left-after-rotate: -#{$arrow-before-after-standard-rotation};

        $arrow-right-before-top-small: 0;
        $arrow-right-after-top-small: 6.5px;

        $arrow-right-before-top-large: 0;
        $arrow-right-after-top-large: 10px;

        $arrow-right-before-rotate: -#{$arrow-before-after-standard-rotation};
        $arrow-right-after-rotate: $arrow-before-after-standard-rotation;

