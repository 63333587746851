






    [data-sparta-container] {
        .spa-center {
            transform-style: preserve-3d; 
            position: relative;
            width: 100%;
            height: 100%;


            &-absolutely {
                @include spa-center-absolutely();
            }


            &-vertically {
                @include spa-center-vertically();
            }


            &-horizontally {

                &--block {
                    margin-left: auto;
                    margin-right: auto;
                }


                &--force {
                    @include spa-center-horizontally();
                }
            }
        }
    }
