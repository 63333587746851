






      [data-sparta-container] {

        background: #fff;
        color: $color-black;
        cursor: auto;
        font-family: $default-font-family;

        .hidden, .hide {
            display: none;
        }

        .spa-hidden {
          display: none !important;
        }

        .preload-images {
            position: absolute;
            left: -10000px;
        }

        .spa-invisible {
            opacity: 0;
        }

        .spa-module-has-rule {
            opacity: 0;
        }
      }
