@import '_config.scss';

@mixin horizontal {
  height: $divider-height;
  width: $divider-width;
  background-color: $divider--bg-color;
  margin-top: auto;
  margin-bottom: auto;
  border: none; 
}

@mixin vertical($color, $direction: 'left') {
  @if $direction == 'left'{
    border-left: $vertical-border-width solid $color;
  }
  @else {
    border-right: $vertical-border-width solid $color;
  }
}

@mixin tertiary {
  background-color: $divider--bg-color-tertiary;
}

[data-sparta-container] {
  .divider {

    &--horizontal {
      @include horizontal();
      &-tertiary {
        @include tertiary();
      }
    }

    &--vertical {
      &-left{
        @include vertical($color: $divider--bg-color);
        &-tertiary {
          @include vertical($color: $divider--bg-color-tertiary)
        }
      }

      &-right {
        @include vertical($divider--bg-color, 'right');
        &-tertiary {
          @include vertical($divider--bg-color-tertiary, 'right')
        }
      }
    }
  }
}
