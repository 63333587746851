






        @import '_config.scss';

        [data-sparta-applicataion] {
          .spa-slider-circle-btn {
              width: 12px;
              height: 12px;
              display: inline-block;
              position: relative;
              padding: 0;
              border: none;
              margin: 0 5px;
              background: $circle-button-default-bg-color;
              font-size: 0;
              border-radius: 6px;
              transition: $spa-standard-transition;

              &:hover,
              &:focus {
                  background-color: $circle-button-default-hover-bg-color;
              }
          }

                    .slick-active .spa-slider-circle-btn,
          .slick-active .spa-slider-circle-btn:hover,
          .slick-active .spa-slider-circle-btn:focus {
              background-color: $circle-button-default-active-bg-color;
          }
        }
