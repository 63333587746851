




        $plus-minus-width-int: 3;
        $plus-minus-size-int: $plus-minus-width-int * 3;

        $plus-minus-size: #{$plus-minus-size-int}px;
        $plus-minus-width: #{$plus-minus-width-int}px;
        $plus-minus-color: $bank-mid-gray;

        $plus-minus-line: $plus-minus-width solid $plus-minus-color;
        $plus-minus-shift: $plus-minus-size-int - $plus-minus-width-int / 2;
        $plus-minus-half: $plus-minus-size-int / 2;
