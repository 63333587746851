

@import '_config.scss';
@import '_mixins.scss';

[data-sparta-container] {

  .spa-btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: $btn-text-align;
    text-decoration: none;
    vertical-align: middle;
    position: relative;
    margin: 0;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition: background-color $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;


    &:hover,
    &:focus {
      text-decoration: none;
    }




    &--primary {
      @include spa-btn--skin($web-button-primary);
    }

    &--secondary {
      @include spa-btn--skin($web-button-secondary);
    }

    &--tertiary {
      @include spa-btn--skin($web-button-tertiary);
    }


















    &--link {
      @include spa-btn--skin($btn-skin--link);
      font-family: $roboto-family, $roboto-font-fallbacks;
      color: $color-royal-one;


      &:hover,
      &:focus {
        text-decoration: underline;
        color: $color-royal-one;
      }

      &:focus {
        outline: 1px solid $spa-link-color-focus;
        border-radius: 2px;
        border-color: transparent;
      }

      &.icon-link {
        &:focus {
          outline: none;
        }
      }
    }



    &[disabled],
    &--disabled {
      @include spa-btn--skin($btn-skin--disabled);

      pointer-events: none;









      &.spa-btn--link {
        background-color: $btn-disabled--link--bg-color;
      }
    }





    &--block {
      @include spa-btn--block;
      height: $btn-medium--height;
      border-radius: $btn-medium--border-radius;
      font-size: $btn-medium--font-size;
      padding-top: $spacing-8;
      padding-bottom: $spacing-8;
    }

    &--xsmall {
      @include spa-btn--xsmall;
    }

    &--small {
      @include spa-btn--small;
    }

    &--medium {
      @include spa-btn--medium;
    }

    &--large {
      @include spa-btn--large;
    }


    &--link {
      padding: 0; 
      height: inherit;
      line-height: inherit;
      border-radius: 0;
      font-size: inherit;
    }












































    &-icon {
      @include btn-text-icon($btn-text-icon);
      font-family: $btn-text-icon-font-family;
      display: flex;
      justify-content: center;
      align-items: center;
      @include btn-text-icon--large;

      .spa-btn-text-icon {
        @include btn-text-icon-size--large;
        border:none;

        svg {
          fill: $color-gray;
        }
      }

      .spa-badge {
        margin: 0 0 0 $spacing-4;
      }

      &.spa-btn-icon--right {
        .spa-btn-text-icon {
          margin-left: 6px;
        }
      }

      &.spa-btn-icon--left {
        .spa-btn-text-icon {
          margin-right: 6px;
        }
      }

      &:hover, 
      &:focus,
      &:active {
        border: 1px solid $color-royal-one;
        text-decoration: none;
        border-radius: $spacing-4;

        .spa-btn-text-icon {
          svg {
            fill: $color-royal-one;
          }
        }
      }

      &:visited {
        color: $color-black;
      }

      &:active {
        border-radius: $spacing-4;

        .spa-btn-text-icon {
          svg {
            fill: $bank-white;
          }
        }
      }

        &.spa-btn--small {
        @include btn-text-icon--small;

        .spa-btn-text-icon {
          @include btn-text-icon-size--small;
        }

        &.spa-btn-icon--right {
          padding: $spacing-4 $spacing-4 $spacing-4 $spacing-8;
          .spa-btn-text-icon {
            margin-left: $spacing-4;
          }
        }

        &.spa-btn-icon--left {
          padding: $spacing-4 $spacing-8 $spacing-4 $spacing-4;
          .spa-btn-text-icon {
            margin-right: $spacing-4;
          }
        }
      }

      &.spa-btn--large {
        @include btn-text-icon--large;

        .spa-btn-text-icon {
          @include btn-text-icon-size--large;
        }

        &.spa-btn-icon--right {
          padding: $spacing-8 $spacing-4 $spacing-8 10px;
          .spa-btn-text-icon {
            margin-left: 6px;
          }
        }

        &.spa-btn-icon--left {
          padding: $spacing-8 10px $spacing-8 $spacing-4;
          .spa-btn-text-icon {
            margin-right: 6px;
          }
        }
      }

      &--left {

        .spa-badge {
          margin: 0 0 0 $spacing-4;
        }
      }

      &--right {
        .spa-btn-text-icon {
          margin-left: 3px;
        }

        .spa-badge {
          margin: 0 $spacing-4 0 0;
        }
      }

      &:hover .spa-icon,
      &:focus .spa-icon {
        border: none;
      }

      &--selected {
        @include btn-text-icon($btn-text-icon-selected);

        .spa-btn-text-icon {
          svg {
            fill: $bank-white;
          }
        }

        &:visited {
          color: $bank-white;
        }

         &:hover {
          .spa-btn-text-icon {
            svg {
              fill: $color-royal-one;
            }
          }
        }

        &:active, 
        &:focus {
          .spa-btn-text-icon {
            svg {
              fill: $bank-white;
            }
          }
        }
      }

      &[disabled],
      &.spa-btn--disabled {
        color: $color-gray-s70;
        background: transparent;
        border: 0 none;

        .spa-icon {
          border: none;
        }

        .spa-btn-text-icon {
          svg {
            fill: $color-gray-s20;
          }
        }
      }

      .spa-btn-text-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -2px $spacing-2 0px;
      }
    }
  }







  .spa-btn--expand-on-medium {
    width: 100%;
    display: block;
  }

  .small-only & {
    .spa-btn--expand-on-small {
      width: 100%;
      display: block;
    }
  }

  .medium-up & {
    .spa-btn--expand-on-small {
      width: auto;
      display: inline-block;
    }
  }

  .large-up & {
    .spa-btn--expand-on-medium {
      width: auto;
      display: inline-block;
    }
  }




  .spa-btn-group {
    .spa-btn {
      margin-right: $spacing-10;
      margin-bottom: $spacing-15;
    }

    .spa-btn--small {
      margin-right: $spacing-5;
    }
  }

  .spa-btn-block-group {
    width: 100%;
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
  }

  .spa-input-btn-group {
    .spa-input-btn-column {
      .spa-btn--medium {
        margin-top: 21px;
        margin-right: $spacing-10;
      }
    }
  }




  .svg-button {
    &:focus {
      outline: 1px dotted $bank-brown-primary;
    }

    &--dark-bg {
      &:focus {
        outline-color: $bank-white;
      }
    }

    &:active {
      box-shadow: none;
    }
  }


  a {
    &.spa-btn {
      &--xsmall {
        line-height: 14px;
      }

      &--medium {
        line-height: 24px;
      }

      &--large {
        line-height: 32px;
      }
    }
  }


  @include build-responsive-button-classes--media-query-version;  
  @include build-responsive-button-classes--class-scoped-version; 
}
