





        @import '_config.scss';

        [data-sparta-container] {
          .spa-arrow {
              &-container {
                  width: 100%;
                  position: relative;
                  z-index: 200;
              }

              &-btn {
                  position: relative;
                  border: none;
                  background: transparent;
                  font-size: 0; 
                  transition: $spa-standard-transition;
                  transform-origin: center center;
                  -webkit-transform-origin: center center;
                  z-index: 1;

                  &:before,
                  &:after {
                      content: '';
                      width: $arrow-before-after-small-width;
                      height: $arrow-before-after-small-height;
                      position: absolute;
                      background: $arrow-gray-skin-color;
                      transition: $spa-standard-transition;
                      z-index: 3;
                  }

                  &:hover,
                  &:focus {
                      background: transparent;
                      outline: none;

                      &:before,
                      &:after {
                          background: $arrow-gray-skin-hover-color;
                      }
                  }

                  &--left {
                      left: 0;

                      @include spa-center-vertically();

                      &:before,
                      &:after {
                          left: $arrow-position-x;
                      }

                      &:before {
                          top: $arrow-left-before-top-small;
                          transform: rotate(#{$arrow-left-before-rotate});
                          -webkit-transform: rotate(#{$arrow-left-before-rotate});
                      }

                      &:after {
                          top: $arrow-left-after-top-small;
                          transform: rotate(#{$arrow-left-after-rotate});
                          -webkit-transform: rotate(#{$arrow-left-after-rotate});

                      }
                  }

                  &--right {
                      right: 0;

                      @include spa-center-vertically();

                      &:before,
                      &:after {
                          right: $arrow-position-x;
                      }

                      &:before {
                          top: $arrow-right-before-top-small;
                          transform: rotate(#{$arrow-right-before-rotate});
                          -webkit-transform: rotate(#{$arrow-right-before-rotate});
                      }

                      &:after {
                          top: $arrow-right-after-top-small;
                          transform: rotate(#{$arrow-right-after-rotate});
                          -webkit-transform: rotate(#{$arrow-right-after-rotate});
                      }
                  }

                  &--learn-more {
                      width: 20px;
                      height: 20px;
                      padding: 0;
                      margin: 0;
                      transition: $spa-standard-transition;
                      transform: none;
                      -webkit-transform: none;
                      transform: rotate($arrow-learn-more-rotation);

                      &:before,
                      &:after {
                          right:8px;
                      }

                      &:before {
                          top: 4px;
                          transform: rotate(#{$arrow-right-before-rotate});
                          -webkit-transform: rotate(#{$arrow-right-before-rotate});
                      }

                      &:after {
                          top: 8px;
                          transform: rotate(#{$arrow-right-after-rotate});
                          -webkit-transform: rotate(#{$arrow-right-after-rotate});
                      }

                      &.active {
                          transform: rotate(90deg);
                          -webkit-transform: rotate(90deg);

                          &:before,
                          &:after {
                              right: 9px;
                          }
                      }
                  }

                  &--toggle {
                      display: inline-block;
                      margin-right: 10px;
                      position: relative;
                      height: 20px;
                      width: 20px;

                      .spa-arrow-btn--learn-more {
                          top: 0;
                      }
                  }

                  &--large {
                      padding: 15px;

                      &:before,
                      &:after {
                          width: $arrow-before-after-large-width;
                          height: $arrow-before-after-large-height;
                      }

                      &.spa-arrow-btn--left {
                          &:before {
                              top: $arrow-left-before-top-large;
                          }

                          &:after {
                              top: $arrow-left-after-top-large;
                          }
                      }

                      &.spa-arrow-btn--right {
                          &:before {
                              top: $arrow-right-before-top-large;
                          }

                          &:after {
                              top: $arrow-right-after-top-large;
                          }
                      }
                  }

                  &--blue {
                      &:hover,
                      &:focus {
                          &:before,
                          &:after {
                              background: $arrow-blue-skin-hover-color;
                          }
                      }

                      &:before,
                      &:after {
                          background: $arrow-blue-skin-color;
                      }
                  }

                  &--red {
                      &:hover,
                      &:focus {
                          &:before,
                          &:after {
                              background: $arrow-red-skin-hover-color;
                          }
                      }

                      &:before,
                      &:after {
                          background: $arrow-red-skin-color;
                      }
                  }

                  &--disabled,
                  &.slick-disabled {
                      &:hover,
                      &:focus {
                          &:before,
                          &:after {
                              background: $arrow-disabled-skin-hover-color;
                          }
                      }

                      &:before,
                      &:after {
                          background: $arrow-disabled-skin-color;
                      }
                  }
              }
          }
        }
