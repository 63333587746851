






    [data-sparta-container] {
        [data-font] {
            visibility: hidden;
        }


        .cnx-light, [data-font="cnx-light"] {
            font-family: $cnx-light-family, $cnx-font-fallbacks;
            font-weight: $light-weight;
            visibility: visible;
        }


        .cnx-light-italic, [data-font="cnx-light-italic"] {
            font-family: $cnx-light-italic-family, $cnx-font-fallbacks;
            font-weight: $light-weight;
            visibility: visible;
        }


        .cnx-regular, [data-font="cnx-regular"] {
            font-family: $cnx-family, $cnx-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;

            strong,
            b {
                font-family: $cnx-bold-family, $cnx-font-fallbacks;
                font-weight: $bold-weight;
                visibility: visible;
            }

            em,
            i {
                font-family: $cnx-italic-family, $cnx-font-fallbacks;
                font-weight: $regular-weight;
                visibility: visible;
            }
        }


        .cnx-italic, [data-font="cnx-italic"] {
            font-family: $cnx-italic-family, $cnx-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;
        }


        .cnx-medium, [data-font="cnx-medium"] {
            font-family: $cnx-medium-family, $cnx-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;
        }


        .cnx-medium-italic, [data-font="cnx-medium-italic"] {
            font-family: $cnx-medium-italic-family, $cnx-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;
        }


        .cnx-bold, [data-font="cnx-bold"] {
            font-family: $cnx-bold-family, $cnx-font-fallbacks;
            font-weight: $bold-weight;
            visibility: visible;
        }


        .cnx-bold-italic, [data-font="cnx-bold-italic"] {
            font-family: $cnx-bold-italic-family, $cnx-font-fallbacks;
            font-weight: $bold-weight;
            visibility: visible;
        }


        .cnx-cond-regular, [data-font="cnx-cond-regular"] {
            font-family: $cnx-family, $cnx-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;
            font-stretch: condensed;

            strong,
            b {
                font-family: $cnx-bold-family, $cnx-font-fallbacks;
                font-weight: $bold-weight;
                visibility: visible;
                font-stretch: condensed;
            }

            em,
            i {
                font-family: $cnx-italic-family, $cnx-font-fallbacks;
                font-weight: $regular-weight;
                visibility: visible;
                font-stretch: condensed;
            }
        }
    }
