
  [data-sparta-container] {
    .text {
      &-left {
        text-align: left !important;
      }

      &-right {
        text-align: right !important;
      }

      &-center {
        text-align: center !important;
      }

      &-justify {
        text-align: justify !important;
      }
    }
  }
