






        @import '_config.scss';

        [data-sparta-container] {
            .spa-table {
                width: 100%;
                margin-top: 0;

                border-collapse: collapse;

                &-container {
                    width: 100%;
                }

                &-header {
                    width: 100%;
                    padding: 10px;
                    margin: 0;
                    color: $bank-white;
                    background: $bank-primary-red;
                    font-family: $cnx-bold-family;
                    font-size: 16px;
                    font-weight: normal;

                    &--secondary {
                        color: $bank-brown-secondary;
                        background: transparent;
                        border-bottom: 1px solid $bank-light-gray-tertiary;
                    }
                }

                table {
                    background: #fff;
                    border: solid 1px #ddd;
                    margin-bottom: 1.25rem;
                    table-layout: auto;
                    border-collapse: collapse;
                    border-spacing: 0;

                    td,
                    th {
                        border: none;
                    }

                    thead {
                        th {
                            background: $white-smoke;
                            color: $table-text-color;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 1.125rem;
                            padding: .5rem .625rem .625rem;
                        }
                    }

                    > tfoot {
                        > tr {
                            background: $table-row-foot-bg-color;
                        }

                        > th {
                            color: $table-text-color;
                            font-size: 16px;
                            font-weight: bold;
                            display: table-cell;
                            line-height: 1.125rem;
                        }
                    }

                    tr {
                        height: 40px;
                        padding: 15px 10px;

                        > td {
                            color: $table-text-color;
                            font-size: 14px;
                        }
                    }


                    tr:nth-child(even) {
                        background: $table-row-even-bg-color;
                    }

                    tr:nth-child(odd) {
                        background: $table-row-odd-bg-color;
                    }
                }
            }
        }
