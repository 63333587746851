@import '_config.scss';





@mixin spa-btn--skin($skin) {
  color: map-get($skin, 'text-color');
  background-color: map-get($skin, 'bg-color');

  @if map-has-key($skin, 'border-color') {
    border: map-get($skin, 'border-width') solid map-get($skin, 'border-color');
  }

  &:visited {
    color: map-get($skin, 'text-color');
  }

  &:hover,
  &:focus,
  &:active {
    background-color: map-get($skin, 'hover-bg-color');
    color: map-get($skin, 'hover-text-color');
  }

  &:hover {
    border: map-get($skin, 'border-width') solid map-get($skin, 'hover-border-color');
  }

  &:focus {
    box-shadow: map-get($skin, 'focus-box-shadow-color');
    border: map-get($skin, 'border-width') solid map-get($skin, 'focus-border-color');
  }

  &:active {
    box-shadow: map-get($skin, 'active-box-shadow-color');

    &:focus {
      outline: map-get($skin, 'active-focus-outline');
    }
  }
}




@mixin spa-btn--xsmall {
  min-width: $btn-xsmall--min-width;
  font-size: $btn-xsmall--font-size;
  padding: $btn-xsmall--padding;
  border-radius: $btn-xsmall--border-radius;
  height: $btn-xsmall--height;

  &:focus {
    text-decoration: none;
    outline: none; 
    border-radius: $btn-xsmall--border-radius;
  }
}

@mixin spa-btn--small {
  font-size: $btn-small--font-size;
  line-height: $btn-small--line-height;
  padding: $btn-small--padding;
  border-radius: $btn-small--border-radius;
  height: $btn-small--height;

  &:focus {
    text-decoration: none;
    outline: none; 
    border-radius: $btn-small--border-radius;
  }
}

@mixin spa-btn--medium {
  font-size: $btn-medium--font-size;
  padding: $btn-medium--padding;
  border-radius: $btn-medium--border-radius;
  height: $btn-medium--height;

  &:focus {
    text-decoration: none;
    outline: none; 
    border-radius: $btn-medium--border-radius;
    ;
  }
}

@mixin spa-btn--large {
  font-size: $btn-large--font-size;
  line-height: $btn-large--line-height;
  padding: $btn-large--padding;
  border-radius: $btn-large--border-radius;
  height: $btn-large--height;

  &:focus {
    text-decoration: none;
    outline: none; 
    border-radius: $btn-large--border-radius;
  }
}








@mixin spa-btn--block {
  display: block;
  width: 100%;
  margin-top: $spacing-8;
  margin-bottom: $spacing-8;
}

$spa-btn--padding-map: (
  xsmall: $btn-xsmall--padding-vertical,
  small: $btn--padding-vertical,
  medium: $btn--padding-vertical,
  large: $btn--padding-vertical,

);

$spa-btn--padding-map--horizontal: (
  xsmall: $btn-xsmall--padding-horizontal,
  small: $btn-small--padding-horizontal,
  medium: $btn-medium--padding-horizontal,
  large: $btn-large--padding-horizontal,

);

$spa-btn--size-map: (
  xsmall, small, medium, large
);


@mixin spa-btn--compensate-for-border($border-width, $hover-border-width: $border-width) {
  @each $modifier in ($spa-btn--size-map) {
    &.#{$btn-base}--#{$modifier} {
      padding-left: (map-get($spa-btn--padding-map--horizontal, '#{$modifier}') - $border-width);
      padding-right: (map-get($spa-btn--padding-map--horizontal, '#{$modifier}') - $border-width);
      padding-top: (map-get($spa-btn--padding-map, '#{$modifier}') - $border-width);
      padding-bottom: (map-get($spa-btn--padding-map, '#{$modifier}') - $border-width);
    }
  }
}

@mixin spa-btn--compensate-for-border-manual($size, $border-width: $btn-bordered--border-width) {
  &:hover,
  &:focus {
    padding-left: (map-get($spa-btn--padding-map--horizontal, '#{$size}') - $border-width);
    padding-right: (map-get($spa-btn--padding-map--horizontal, '#{$size}') - $border-width);
  }
}











@mixin build-responsive-button-classes-for-breakpoint($breakpoint-name) {
  @each $modifier in (xsmall,small, medium, large, engagement, block)  {
    .#{$btn-base}--#{$modifier}-at-#{$breakpoint-name} {

      @if $modifier == xsmall {
        @include spa-btn--xsmall;
        @include spa-btn--compensate-for-border-manual(xsmall);
        line-height: $btn-small--line-height - 4;
      }

      @else if $modifier == small {
        @include spa-btn--small;
        @include spa-btn--compensate-for-border-manual(small);
        line-height: $btn-small--line-height - 4;
      }

      @else if $modifier == medium {
        @include spa-btn--medium;
        @include spa-btn--compensate-for-border-manual(medium);
      }

      @else if $modifier == large {
        @include spa-btn--large;
        @include spa-btn--compensate-for-border-manual(large);
      }







      @else if $modifier == block {
        @include spa-btn--block;
      }
    }
  }
}







@mixin build-responsive-button-classes--media-query-version {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    @media #{$breakpoint-query} {
      @include build-responsive-button-classes-for-breakpoint($breakpoint-name);
    }
  }
}





@mixin build-responsive-button-classes--class-scoped-version {
  @each $breakpoint-name, $breakpoint-query in $breakpoint-list {
    .#{$breakpoint-name} & {
      @include build-responsive-button-classes-for-breakpoint($breakpoint-name);
    }
  }
}






@mixin btn-text-icon($skin) {
  color: map-get($skin, 'text-color');
  background-color: map-get($skin, 'bg-color');

  @if map-has-key($skin, 'border-color') {
    border: map-get($skin, 'border-width') solid map-get($skin, 'border-color');
  }

  &:visited {
    color: map-get($skin, 'text-color');
  }

  &:disabled  {
    color: map-get($skin, 'disabled-text-color');
    background: map-get($skin, 'disabled-bg-color');
    @if map-has-key($skin, 'disabled-border') {
      border: map-get($skin, 'disabled-border');
    }
  }

  &:hover  {
    color: map-get($skin, 'hover-text-color');
    background: map-get($skin, 'hover-bg-color');

    @if map-has-key($skin, 'hover-border-width') {
      border: map-get($skin, 'hover-border-width') solid map-get($skin, 'hover-border-color');
    }
  }

  &:focus {
    color: map-get($skin, 'focus-text-color');
    background: map-get($skin, 'focus-bg-color');

    @if map-has-key($skin, 'focus-border-width') {
      border: map-get($skin, 'focus-border-width') solid map-get($skin, 'focus-border-color');
     }

    @if map-has-key($skin, 'focus-box-shadow') {
      box-shadow: map-get($skin, 'focus-box-shadow');
    }
  }
  &:active {
    color: map-get($skin, 'active-text-color');
    background: map-get($skin, 'active-bg-color');

    @if map-has-key($skin, 'active-border-width') {
      border: map-get($skin, 'active-border-width') solid map-get($skin, 'active-border-color');
    }

    @if map-has-key($skin, 'active-box-shadow') {
      box-shadow: map-get($skin, 'active-box-shadow');
    }
  }
}


@mixin btn-text-icon--small() {
  border-radius: $btn-text-icon-radius;
  padding: $spacing-4 $spacing-8;
  font-size: $btn-text-icon-small--font-size;
  line-height: $btn-text-icon-small--line-height;
  height: $btn-text-icon-small--height;
}

@mixin btn-text-icon--large() {
  border-radius: $btn-text-icon-radius;
  padding: 10px $spacing-8;
  font-size: $btn-text-icon-large--font-size;
  line-height: $btn-text-icon-large--line-height;
  height: $btn-text-icon-large--height;
}

@mixin btn-text-icon-size--small() {
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
}

@mixin btn-text-icon-size--large() {
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}