




@mixin build-font($family, $weight: 300, $style: normal, $variant: normal) {
  font-family: $family;
  src: url('../font/#{$family}/#{$family}.eot');
  src: url('../font/#{$family}/#{$family}.eot?#iefix') format('embedded-opentype'),
    url('../font/#{$family}/#{$family}.woff2') format('woff2'),
    url('../font/#{$family}/#{$family}.woff') format('woff'),
    url('../font/#{$family}/#{$family}.ttf') format('truetype');
  font-weight: $weight;
  font-style: $style;
  font-variant: $variant;
}


@font-face {
  @include build-font($cnx-light-family, $light-weight, normal);
}


@font-face {
  @include build-font($cnx-light-italic-family, $light-weight, italic);
}


@font-face {
  @include build-font($cnx-family, $regular-weight, normal);
}


@font-face {
  @include build-font($cnx-italic-family, $regular-weight, italic);
}


@font-face {
  @include build-font($cnx-medium-family, $regular-weight, normal);
}


@font-face {
  @include build-font($cnx-medium-italic-family, $regular-weight, italic);
}


@font-face {
  @include build-font($cnx-bold-family, $bold-weight, normal);
}


@font-face {
  @include build-font($cnx-bold-italic-family, $bold-weight, italic);
}


@font-face {
  @include build-font($cnx-cond-family, $regular-weight, normal);
}


@font-face {
  @include build-font($cnx-italic-cond-family, $regular-weight, italic);
}


@font-face {
  @include build-font($roboto-family, $regular-weight, normal);
}


@font-face {
  @include build-font($roboto-italic-family, $regular-weight, italic);
}


@font-face {
  @include build-font($roboto-medium-family, $regular-weight, normal);
}


@font-face {
  @include build-font($roboto-medium-italic-family, $regular-weight, italic);
}


@font-face {
  @include build-font($roboto-bold-family, $bold-weight, normal);
}


@font-face {
  @include build-font($roboto-bold-italic-family, $bold-weight, italic);
}


@font-face {
  @include build-font($roboto-mono-family, $type-weight-regular, normal);
}


@font-face {
  @include build-font($roboto-mono-medium-family, $type-weight-regular, normal);
}


@font-face {
  @include build-font($roboto-mono-bold-family, $type-weight-bold, normal);
}
