

@import '_config.scss';
@import '_mixins.scss';

body.small-only [data-sparta-container] .spa-button-group.spa-button-group--right,
body.medium-only [data-sparta-container] .spa-button-group.spa-button-group--right.spa-button-group--block-at-medium {
  flex-direction: column;
  width: 100%;

  .spa-btn {
    margin: $spacing-12 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

[data-sparta-container] {
  .spa-button-group {
    &.spa-button-group--right {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      .spa-btn {
        margin: 0 $spacing-12;

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &.spa-button-group--block {
      display: flex;
      flex-direction: column;
      width: 100%;

      .spa-btn {
        margin: $spacing-12 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

}