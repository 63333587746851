
$icon-default: $color-black;
$icon-gray: $color-gray;
$icon-medium-gray: $color-gray-s70;
$icon-gray-s20: $color-gray-s20;
$icon-white: $color-brand-tertiary;
$icon-dark-blue: $color-brand-secondary;
$icon-red: $color-regal-one;
$icon-social-hover: $color-gray-s10;
$icon-default-hover: $color-royal-one;
$social-hover-border-radius: 4px;

$color-map: (
  black: $icon-default,
  gray: $icon-gray,
  gray-20: $icon-gray-s20,
  gray-70: $icon-medium-gray,
  white: $icon-white,
  dark-blue: $icon-dark-blue,
  light-blue: $icon-default-hover,
  red: $icon-red,
  social-hover: $icon-social-hover,
  disabled: $btn-disabled--text-color
);

$control-alt-colors: ('gray', 'gray-20', 'white', 'light-blue', 'red');
$indicator-alt-colors: ('gray', 'gray-20', 'white', 'light-blue', 'red');
$loader-alt-colors: ('black', 'white', 'light-blue', 'dark-blue');
$social-alt-colors: ('white', 'black');
$indicator-warning-alt-colors: ('gray', 'gray-20', 'white', 'light-blue', 'red');
$link-alt-colors: ('gray', 'light-blue', 'dark-blue');
$brand-alt-colors: ('gray', 'gray-20', 'white', 'light-blue', 'dark-blue');


@keyframes icon-loading-animation {
  100% {
    transform: rotate(360deg);
  }
}











$icon-map: (
  arrow-back: (
    name: arrowBack,
    type: control,
    alt-colors: $control-alt-colors
  ),
  arrow-down: (
    name: arrowDown,
    type: control,
    alt-colors: $control-alt-colors
  ),
  arrow-forward: (
    name: arrowForward,
    type: control,
    alt-colors: $control-alt-colors
  ),
  arrow-up: (
    name: arrowUp,
    type: control,
    alt-colors: $control-alt-colors
  ),
  bell: (
    name: bell,
    type: control,
    alt-colors: $control-alt-colors
  ),
  brand-help: (
    name: brandHelp,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  brand-info: (
    name: brandInfo,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  brand-success: (
    name: brandSuccess,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  brand-warning: (
    name: brandWarning,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  calculator: (
    name: calculator,
    type: control,
    alt-colors: $control-alt-colors
  ),
  calendar: (
    name: calendar,
    type: control,
    alt-colors: $control-alt-colors
  ),
  chat: (
    name: chat,
    type: control,
    alt-colors: $control-alt-colors
  ),
  copy: (
    name: copy,
    type: control,
    alt-colors: $control-alt-colors
  ),
  card: (
    name: card,
    type: indicator,
    alt-colors: $indicator-alt-colors,
  ),
  caret-down-small: (
    name: caretDownSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  caret-left: (
    name: caretLeft,
    type: control,
    alt-colors: $control-alt-colors
  ),
  caret-left-small: (
    name: caretLeftSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  caret-right: (
    name: caretRight,
    type: control,
    alt-colors: $control-alt-colors
  ),
  caret-right-small: (
    name: caretRightSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  caret-up-small: (
    name: caretUpSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  cart: (
    name: cart,
    type: control,
    alt-colors: $control-alt-colors
  ),
  cash: (
    name: cash,
    type: indicator,
    alt-colors: $indicator-alt-colors,
  ),
  check: (
    name: check,
    type: control,
    alt-colors: $control-alt-colors
  ),
  check-small: (
    name: checkSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  chevron-down: (
    name: chevronDown,
    type: control,
    alt-colors: $control-alt-colors
  ),
  chevron-up: (
    name: chevronUp,
    type: control,
    alt-colors: $control-alt-colors
  ),
  close: (
    name: close,
    type: control,
    alt-colors: $control-alt-colors
  ),
  close-small: (
    name: closeSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  document: (
    name: document,
    type: control,
    alt-colors: $control-alt-colors
  ),
  edit: (
    name: edit,
    type: control,
    alt-colors: $control-alt-colors
  ),
  facebook: (
    name: facebook,
    type: social,
    alt-colors: $social-alt-colors
  ),
  help: (
    name: help,
    type: indicator,
    alt-colors: $indicator-alt-colors,
    focus-icon-name: helpFocus
  ),
  history: (
    name: history,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  incomplete: (
    name: incomplete,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  info: (
    name: info,
    type: indicator,
    alt-colors: $indicator-alt-colors,
    focus-icon-name: infoFocus
  ),
  instagram: (
    name: instagram,
    type: social,
    alt-colors: $social-alt-colors
  ),
  linked-in: (
    name: linked-in,
    type: social,
    alt-colors: $social-alt-colors
  ),
  loader-24: (
    name: loader-24,
    type: null,
    alt-colors: $loader-alt-colors
  ),
  loader-48: (
    name: loader-48,
    type: null,
    alt-colors:$loader-alt-colors
  ),
  loader-72: (
    name: loader-72,
    type: null,
    alt-colors: $loader-alt-colors
  ),
  loader-100: (
    name: loader-100,
    type: null,
    alt-colors: $loader-alt-colors
  ),
  location: (
    name: location,
    type: control,
    alt-colors: $control-alt-colors
  ),
  mail: (
    name: mail,
    type: control,
    alt-colors: $control-alt-colors
  ),
  menu: (
    name: menu,
    type: control,
    alt-colors: $control-alt-colors
  ),
  minus: (
    name: minus,
    type: control,
    alt-colors: $control-alt-colors
  ),
  notes: (
    name: notes,
    type: control,
    alt-colors: $control-alt-colors
  ),
  overflow: (
    name: overflow,
    type: control,
    alt-colors: $control-alt-colors
  ),
  pause: (
    name: pause,
    type: control,
    alt-colors: $control-alt-colors
  ),
  phone: (
    name: phone,
    type: control,
    alt-colors: $control-alt-colors
  ),
  pinterest: (
    name: pinterest,
    type: social,
    alt-colors: $social-alt-colors
  ),
  play: (
    name: play,
    type: control,
    alt-colors: $control-alt-colors
  ),
  plus: (
    name: plus,
    type: control,
    alt-colors: $control-alt-colors
  ),
  search: (
    name: search,
    type: control,
    alt-colors: $control-alt-colors
  ),
  success: (
    name: success,
    type: indicator,
    alt-colors: $indicator-alt-colors,
    focus-icon-name: successFocus
  ),
  twitter: (
    name: twitter,
    type: social,
    alt-colors: $social-alt-colors
  ),
  user: (
    name: user,
    type: control,
    alt-colors: $control-alt-colors
  ),
  volume-off: (
    name: volumeOff,
    type: control,
    alt-colors: $control-alt-colors
  ),
  volume-on: (
    name: volumeOn,
    type: control,
    alt-colors: $control-alt-colors
  ),
  warning: (
    name: warning,
    type: indicator,
    alt-colors: $indicator-warning-alt-colors,
    focus-icon-name: warningFocus
  ),
  youtube: (
    name: youtube,
    type: social,
    alt-colors: $social-alt-colors
  ),


  dollarArrow: (
    name: dollarArrow,
    type: standard,
    alt-colors: $link-alt-colors
  ),
  dollarBurst: (
    name: dollarBurst,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  dollarSign: (
    name: dollarSign,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  doubleArrow: (
    name: doubleArrow,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  helpThin: (
    name: helpThin,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  piggyBank: (
    name: piggyBank,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  searchThin: (
    name: searchThin,
    type: standard,
    alt-colors: $link-alt-colors,
  ),


  archive: (
    name: archive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  arrow-down-small: (
    name: arrowDownSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  arrow-up-small: (
    name: arrowUpSmall,
    type: control,
    alt-colors: $control-alt-colors
  ),
  dashboard:(
    name:dashboard,
    type:control,
    alt-colors: $control-alt-colors
  ),
  attach: (
    name: attach,
    type: control,
    alt-colors: $control-alt-colors
  ),
  bookmark: (
    name: bookmark,
    type: control,
    alt-colors: $control-alt-colors
  ),
  bookmark-active: (
    name: bookmarkActive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  chart-view: (
    name: chartView,
    type: control,
    alt-colors: $control-alt-colors
  ),
  chart-view-active: (
    name: chartViewActive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  customize: (
    name: customize,
    type: control,
    alt-colors: $control-alt-colors
  ),
  delete: (
    name: delete,
    type: control,
    alt-colors: $control-alt-colors
  ),
  denied: (
    name: denied,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  download: (
    name: download,
    type: control,
    alt-colors: $control-alt-colors
  ),
  eight: (
    name: eight,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  eight-active: (
    name: eightActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  export: (
    name: export,
    type: control,
    alt-colors: $control-alt-colors
  ),
  filter: (
    name: filter,
    type: control,
    alt-colors: $control-alt-colors
  ),
  flag-active: (
    name: flagActive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  flag-inactive: (
    name: flagInactive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  five: (
    name: five,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  five-active: (
    name: fiveActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  folder: (
    name: folder,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  forward: (
    name: forward,
    type: control,
    alt-colors: $control-alt-colors
  ),
  four: (
    name: four,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  four-active: (
    name: fourActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  fullscreen: (
    name: fullscreen,
    type: control,
    alt-colors: $control-alt-colors
  ),
  fullscreen-exit: (
    name: fullscreenExit,
    type: control,
    alt-colors: $control-alt-colors
  ),
  full-view: (
    name: fullView,
    type: control,
    alt-colors: $control-alt-colors
  ),
  home: (
    name: home,
    type: control,
    alt-colors: $control-alt-colors
  ),
  language: (
    name: language,
    type: control,
    alt-colors: $control-alt-colors
  ),
  launch: (
    name: launch,
    type: control,
    alt-colors: $control-alt-colors
  ),
  limited-view: (
    name: limitedView,
    type: control,
    alt-colors: $control-alt-colors
  ),
  lock: (
    name: lock,
    type: standard,
    alt-colors: $link-alt-colors,
  ),
  log-out: (
    name: logout,
    type: control,
    alt-colors: $control-alt-colors,
  ),
  new-folder: (
    name: newFolder,
    type: control,
    alt-colors: $control-alt-colors
  ),
  nine: (
    name: nine,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  nine-active: (
    name: nineActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  note: (
    name: note,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  one: (
    name: one,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  one-active: (
    name: oneActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  print: (
    name: print,
    type: control,
    alt-colors: $control-alt-colors
  ),
  refresh: (
    name: refresh,
    type: control,
    alt-colors: $control-alt-colors
  ),
  reply: (
    name: reply,
    type: control,
    alt-colors: $control-alt-colors
  ),
  return-to-top: (
    name: returnToTop,
    type: control,
    alt-colors: $control-alt-colors
  ),
  save: (
    name: save,
    type: control,
    alt-colors: $control-alt-colors
  ),
  save-active: (
    name: saveActive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  settings: (
    name: settings,
    type: control,
    alt-colors: $control-alt-colors
  ),
  seven: (
    name: seven,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  seven-active: (
    name: sevenActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  share: (
    name: share,
    type: control,
    alt-colors: $control-alt-colors
  ),
  six: (
    name: six,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  six-active: (
    name: sixActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  sort-all: (
    name: sortAll,
    type: control,
    alt-colors: $control-alt-colors
  ),
  star: (
    name: star,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  star-active: (
    name: starActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  table-view: (
    name: tableView,
    type: control,
    alt-colors: $control-alt-colors
  ),
  table-view-active: (
    name: tableViewActive,
    type: control,
    alt-colors: $control-alt-colors
  ),
  ten: (
    name: ten,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  ten-active: (
    name: tenActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  three: (
    name: three,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  three-active: (
    name: threeActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  two: (
    name: two,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  two-active: (
    name: twoActive,
    type: indicator,
    alt-colors: $indicator-alt-colors
  ),
  upload: (
    name: upload,
    type: control,
    alt-colors: $control-alt-colors
  )
);