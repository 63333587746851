
[data-sparta-container] {
  .show-for-small-only,
  .show-for-small-up {
    display: inherit !important;
  }

  .hide-for-small-only,
  .hide-for-small-up,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }

  .medium-up & {
    .hide-for-medium-only,
    .hide-for-medium-up,
    .show-for-small-only {
      display: none !important;
    }

    .hide-for-small-only,
    .show-for-medium-only,
    .show-for-medium-up {
      display: inherit !important;
    }
  }

  .large-up & {
    .hide-for-large-only,
    .hide-for-large-up,
    .show-for-medium-only {
      display: none !important;
    }

    .hide-for-medium-only,
    .hide-for-small-only,
    .show-for-large-only,
    .show-for-large-up {
      display: inherit !important;
    }
  }

  .xlarge-up & {
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .show-for-large-only {
      display: none !important;
    }

    .hide-for-large-only,
    .hide-for-medium-only,
    .hide-for-small-only,
    .show-for-xlarge-only,
    .show-for-xlarge-up {
      display: inherit !important;
    }
  }

  .xxlarge-up & {
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-xxlarge-only {
      display: none !important;
    }

    .hide-for-large-only,
    .hide-for-medium-only,
    .hide-for-small-only,
    .hide-for-xlarge-only,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: inherit !important;
    }
  }
}

[data-sparta-wrapper].sparta-media-query-utility-loading:not([data-sparta-widget]),
[data-sparta-container].spa-ui-layer.spa-ui-modal {
  .show-for-small-only,
  .show-for-small-up {
    display: inherit !important;
  }

  .hide-for-small-only,
  .hide-for-small-up,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up {
    display: none !important;
  }

  @media #{$medium-up} {
    .hide-for-medium-only,
    .hide-for-medium-up,
    .show-for-small-only {
      display: none !important;
    }

    .hide-for-small-only,
    .show-for-medium-only,
    .show-for-medium-up {
      display: inherit !important;
    }
  }

  @media #{$large-up} {
    .hide-for-large-only,
    .hide-for-large-up,
    .show-for-medium-only {
      display: none !important;
    }

    .hide-for-medium-only,
    .hide-for-small-only,
    .show-for-large-only,
    .show-for-large-up {
      display: inherit !important;
    }
  }

  .xlarge-up & {
    .hide-for-xlarge-only,
    .hide-for-xlarge-up,
    .show-for-large-only {
      display: none !important;
    }

    .hide-for-large-only,
    .hide-for-medium-only,
    .hide-for-small-only,
    .show-for-xlarge-only,
    .show-for-xlarge-up {
      display: inherit !important;
    }
  }

  .xxlarge-up & {
    .hide-for-xxlarge-only,
    .hide-for-xxlarge-up,
    .show-for-xlarge-only {
      display: none !important;
    }

    .hide-for-large-only,
    .hide-for-medium-only,
    .hide-for-small-only,
    .hide-for-xlarge-only,
    .show-for-xxlarge-only,
    .show-for-xxlarge-up {
      display: inherit !important;
    }
  }
}

[data-sparta-container] {
  .mda-show,
  .show-for-mda {
    display: none;

    .mda & {
      display: inherit !important;
    }
  }

  .mda-hide,
  .hide-for-mda {

    .mda & {
      display: none !important;
    }
  }
}
