




@import "../../mixins/mixins";
@import "./_config.scss";
@import "./svg-colors.scss";

@mixin set-icon($name, $color) {
  background-image: icon($name, (color: (map-get($color-map, $color))));
}

@mixin set-icon-by-path($name, $color) {
  background-image: url(../svg/#{$name}-#{$color}.svg);
}

@mixin alt-colors($name, $alt-colors, $hover-color, $focus-icon-name: null) {
  @each $color in $alt-colors {
    &--#{$color} {
      @include icons-common-style;

      &::before {
        content: ' ';
        @include set-icon-by-path($name, $color);
      }

      &:hover::after,
      &:focus::after {
        @if ($focus-icon-name ==null) {
          @include set-icon-by-path($name, $hover-color);
        }

        @else {
          @include set-icon-by-path($focus-icon-name, $hover-color);
        }
      }
    }
  }
}


@mixin create-icons() {

  @each $name,
  $params in $icon-map {
    .icon-#{$name} {
      $icon-name: map-get($params, name);
      $type: map-get($params, type);
      $alt-colors: map-get($params, alt-colors);
      $focus-icon-name: map-get($params, focus-icon-name);

      @if $type !=null {
        @include icons-common-style;

        &::after {
          @if $focus-icon-name {
            @include set-icon-by-path($focus-icon-name, 'light-blue');
          }

          @else if $type ==social {
            @include set-icon-by-path($icon-name, 'black');
          }

          @else {
            @include set-icon-by-path($icon-name, 'light-blue');
          }
        }

        &::before {
          @if $type ==social {
            @include set-icon-by-path($icon-name, 'black');
          }

          @else {
            @include set-icon-by-path($icon-name, 'gray');
          }
        }


      }

      @if $type ==control {
        @if $alt-colors {
          @include alt-colors($icon-name, $alt-colors, 'light-blue');
        }
      }

      @else if $type ==indicator {
        @if $alt-colors {
          @if $focus-icon-name {
            @include alt-colors($icon-name, $alt-colors, 'light-blue', $focus-icon-name);
          }

          @else {
            @include alt-colors($icon-name, $alt-colors, 'light-blue');
          }
        }
      }

      @else if $type ==social {
        @if $alt-colors {
          @include alt-colors($icon-name, $alt-colors, 'white');
        }
      }

      @else if $type ==standard {
        @if $alt-colors {
          @include alt-colors($icon-name, $alt-colors, 'black');
        }

        @include set-icon-by-path($icon-name, 'black');
      }

    }
  }
}



@mixin create-circle-btn-icons {

  @each $name,
  $params in $icon-map {
    $icon: map-get($params, name);

    .spa-circle-btn-wrapper {
      .circle-icon-#{$name} {
        @include set-icon-by-path($icon, 'dark-blue');
      }

      &:hover .circle-icon-#{$name},
      &:focus .circle-icon-#{$name} {
          @include set-icon-by-path($icon, 'light-blue');
      }

              &:active .circle-icon-#{$name},
      &.spa-circle-btn--selected .circle-icon-#{$name}, 
      &.spa-circle-btn--selected:focus .circle-icon-#{$name} {
        @include set-icon-by-path($icon, 'white');
      }

      &[disabled] .circle-icon-#{$name},
      &--disabled .circle-icon-#{$name} {
        @include set-icon-by-path($icon, 'gray-20');
      }
    }
  }
}

@mixin darken-social-hover-border {

  @each $name,
  $params in $icon-map {
    $icon-name: map-get($params, name);
    $type: map-get($params, type);

    .icon-#{$name} {
      @if $type ==social {
        &:hover {
          border: 1px solid $color-black;
          border-radius: 2px;
          outline: 0;
        }
      }

      &--white {
        &:hover {
          border: 1px solid $color-brand-tertiary;

          &::after {
            @include set-icon-by-path($name, 'white');
          }
        }
      }
    }
  }
}

@mixin icons-common-style {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  &::after {
    opacity: 0;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
  }

  &:hover::before {
    opacity: 0;
  }

  &:hover {
    border-color: $color-royal-one;
    border-radius: 2px;
    outline: 0;
    box-sizing: border-box;
  }
}



$SvgBdrColor: transparent;
$SvgFillColor: $body-text-color;
$SvgFillColorInteractive: $color-gray;
$SvgFillColorDark: $color-brand-tertiary;

$SvgBdrColorActive: $color-royal-one;
$SvgFillColorActive: $color-royal-one;


[data-sparta-container] {
  @include create-icons();
  @include create-circle-btn-icons;
  @include svg-color-classes;

  .spa-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 44px;
    height: 44px;
    background-position: center center;
    background-size: auto;
    border: 1px solid transparent;
  }


  .spa-icon--helper {
    border: 1px solid $SvgBdrColor;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background: none;
    font-family: $spa-font-family;
    font-style: normal;

    svg {
      fill: $SvgFillColor;

      &:not(:first-child) {
        display: none;
      }
    }

    &.spa-icon--interactive {
      outline: 0;

      svg {
        fill: $SvgFillColorInteractive;
      }

      &:hover,
      &:focus {
        border-color: $SvgBdrColorActive;

        svg {
          fill: $SvgFillColorActive;
        }
      }
    }

    &.spa-icon--hasMulti {
      svg {
        &:last-child {
          display: none;
        }
      }

      &:hover,
      &:focus {
        svg {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: flex;
          }
        }
      }
    }

    &.spa-icon--hasText {
      height: auto;
      width: auto;
      color: $body-text-color;

      &.spa-icon--interactive {
        padding: $spacing-8;
        color: $SvgFillColorInteractive;

        &:hover,
        &:focus {
          color: $SvgFillColorActive;
        }
      }

      &.spa-icon--textPos {
        &-left svg {
          margin-left: $spacing-8;
        }

        &-right {
          svg {
            margin-right: $spacing-8;
          }

          .spa-icon-badge-wrapper {
            margin-right: 8px;
          } 

          .spa-badge {
            top: -5px;
            right: 5px;

            &--numeric {
              top: -10px;
              right: -10px;
            }
          }
        }
      }
    }

    &.spa-icon--darkBackground {
      padding: $spacing-8;

      svg {
        fill: $SvgFillColorDark;
      }

      &.spa-icon--interactive {

        &:hover,
        &:focus {
          border-color: $SvgFillColorDark;

          svg {
            fill: $SvgFillColorDark;
          }
        }
      }

      &.spa-icon--hasText {
        color: $SvgFillColorDark;

        &.spa-icon--interactive {

          &:hover,
          &:focus {
            color: $SvgFillColorDark;
          }
        }
      }

    }

      &.spa-icon--has-badge {
        position: relative;
        display: inline-block;

          &.spa-icon--hasText {
          svg {
            margin-bottom: -5px;
          }
        }

          &.spa-icon--textPos-right {
          margin-right: 8px;
        }

          .spa-icon-badge-wrapper {
          display: inline-block;
          position: relative;
        }

          .spa-badge {
          top: -8px;
          right: -8px;

            &--numeric {
            top: -10px;
            right: -16px;
          }
        }
      }
  }

  @include darken-social-hover-border;


  .spa-icon-loading {
    position: relative;
    text-align: justify;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: $spacing-10 $spacing-10 $spacing-4;
    background-image: none;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;

      background-size: contain !important;
      animation: icon-loading-animation 2s linear infinite;
    }

    &.icon-loader-24:before {
      @include set-icon-by-path(loader-24, 'black');
    }

    &.icon-loader-48:before {
      @include set-icon-by-path(loader-48, 'black');
    }

    &.icon-loader-72:before {
      @include set-icon-by-path(loader-72, 'black');
    }

    &.icon-loader-100:before {
      @include set-icon-by-path(loader-100, 'black');
    }
  }
}