
 .foundation-type-default { color: pink;}

  [data-sparta-container] {

    div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,p,blockquote,th,td {
        margin: 0;
        padding: 0
    }

    a {
        line-height: inherit;
        text-decoration: none
    }

    a img {
        border: 0
    }

    p {
        font-family: inherit;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.6;
        margin-bottom: 1.25rem;
        text-rendering: optimizeLegibility
    }

    p.lead {
        font-size: 1.21875rem;
        line-height: 1.6
    }

    p aside {
        font-size: .875rem;
        font-style: italic;
        line-height: 1.35
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: $cnx-bold-family;
        font-style: normal;
        font-weight: normal;
        line-height: 1.4;
        margin-bottom: .5rem;
        margin-top: .2rem;
        text-rendering: optimizeLegibility
    }

    h1 small,h2 small,h3 small,h4 small,h5 small,h6 small {
        color: #6f6f6f;
        font-size: 60%;
        line-height: 0
    }

    h1 {
        font-size: 2.125rem
    }

    h2 {
        font-size: 1.6875rem
    }

    h3 {
        font-size: 1.375rem
    }

    h4 {
        font-size: 1.125rem
    }

    h5 {
        font-size: 1.125rem
    }

    h6 {
        font-size: 1rem
    }

    .subheader {
        line-height: 1.4;
        color: #6f6f6f;
        font-weight: normal;
        margin-top: .2rem;
        margin-bottom: .5rem
    }

    hr {
        border: solid #ddd;
        border-width: 1px 0 0;
        clear: both;
        height: 0;
    }

    em,i {
        font-style: italic;
        line-height: inherit
    }

    strong,b {
        font-weight: bold;
        line-height: inherit
    }

    small {
        font-size: 60%;
        line-height: inherit
    }

    code {
        background-color: #f8f8f8;
        border-color: #dfdfdf;
        border-style: solid;
        border-width: 1px;
        color: #333;
        font-family: Consolas,"Liberation Mono",Courier,monospace;
        font-weight: normal;
        padding: .125rem .3125rem .0625rem
    }

    ul,ol,dl {
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.6;
        list-style-position: outside;
        margin-bottom: 1.25rem
    }

    ul {
        margin-left: 1.1rem
    }

        ol {
        margin-left: 1.4rem
    }

        ul li ul,ul li ol,
    ol li ul,ol li ol {
        margin-left: 1.25rem;
        margin-bottom: 0
    }

    ul.no-bullet li ul,ul.no-bullet li ol {
        list-style: none
    }

    ul.square li ul,ul.circle li ul,ul.disc li ul {
        list-style: inherit
    }

    ul.square {
        list-style-type: square;
    }

    ul.circle {
        list-style-type: circle;
    }

    ul.disc {
        list-style-type: disc;
    }

    ul.no-bullet {
        list-style: none
    }

        dl dt {
        margin-bottom: .3rem;
        font-weight: bold
    }

    dl dd {
        margin-bottom: .75rem
    }

    abbr,acronym {
        text-transform: uppercase;
        font-size: 90%;
        color: #222;
        cursor: help
    }

    abbr {
        text-transform: none
    }

    abbr[title] {
        border-bottom: 1px dotted #ddd
    }

    blockquote {
        margin: 0 0 1.25rem;
        padding: .5625rem 1.25rem 0 1.1875rem;
        border-left: 1px solid #ddd
    }

    blockquote cite {
        display: block;
        font-size: .8125rem;
        color: #555
    }

    blockquote cite:before {
        content: "\2014 \0020"
    }

    blockquote cite a,blockquote cite a:visited {
        color: #555
    }

    blockquote,blockquote p {
        line-height: 1.6;
        color: #6f6f6f
    }


    @media all and (min-width: 48em) {
        h1,h2,h3,h4,h5,h6 {
            line-height:1.4
        }

        h1 {
            font-size: 2.75rem
        }

        h2 {
            font-size: 2.3125rem
        }

        h3 {
            font-size: 1.6875rem
        }

        h4 {
            font-size: 1.4375rem
        }

        h5 {
            font-size: 1.125rem
        }

        h6 {
            font-size: 1rem
        }
    }

  }
