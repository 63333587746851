


            $table-text-color: $color-black;

            $table-row-foot-bg-color: $bank-light-gray-tertiary;

            $table-row-even-bg-color: $bank-light-gray-primary;
            $table-row-odd-bg-color: $bank-white;

            $table-no-header-border-top-width: 1px;
            $table-no-header-border-top-style: solid;
            $table-no-header-border-top-color: $bank-light-gray-tertiary;

            $table-no-header-border-top: $table-no-header-border-top-width $table-no-header-border-top-style $table-no-header-border-top-color;

