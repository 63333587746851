
  [data-sparta-container] {
    .radius {
      border-radius: 1000px;
    }

    .round {
      border-radius: 1000px;
    }
  }
