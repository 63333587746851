



$image-width-threshold: 1920px; 

[data-sparta-container] {
  .picture-box {
    overflow: hidden;
    position: relative;

    @media (min-width: $image-width-threshold) {
      display: flex;
      justify-content: center;
    }

    & > picture {
      z-index: 0;
      display: table; 
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: auto;

      & > img {
        width: auto;
        max-width: $image-width-threshold;
      }
    }

    &.crop-from-left picture {
      position: absolute;
      left: auto;
      right: 0;
      transform: none;

      @media (min-width: $image-width-threshold) {
        position: relative;
      }
    }

    &.crop-small-from-left picture {
      @media #{$small-only} {
        position: absolute;
        left: auto;
        right: 0;
        transform: none;
      }
    }

    &.crop-medium-from-left picture {
      @media #{$medium-only} {
        position: absolute;
        left: auto;
        right: 0;
        transform: none;
      }
    }

    &.crop-large-from-left picture {
      @media #{$large-up} {
        position: absolute;
        left: auto;
        right: 0;
        transform: none;
      }

      @media (min-width: $image-width-threshold) {
        position: relative;
      }
    }

    &.crop-from-right picture {
      position: absolute;
      left: 0;
      right: auto;
      transform: none;

      @media (min-width: $image-width-threshold) {
        position: relative;
      }
    }

    &.crop-small-from-right picture {
      @media #{$small-only} {
        position: absolute;
        left: 0;
        right: auto;
        transform: none;
      }
    }

    &.crop-medium-from-right picture {
      @media #{$medium-only} {
        position: absolute;
        left: 0;
        right: auto;
        transform: none;
      }
    }

    &.crop-large-from-right picture {
      @media #{$large-up} {
        position: absolute;
        left: 0;
        right: auto;
        transform: none;
      }

      @media (min-width: $image-width-threshold) {
        position: relative;
      }
    }

    .picture-box__content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
