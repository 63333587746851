
  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
      height: auto;
    }

    99% {
      visibility: hidden;
      opacity: 0;
      height: auto;
    }

    100% {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }

    1% {
      visibility: visible;
      height: auto;
      opacity: 0;
    }

    100% {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
  @keyframes noneOut {
    0% {
      visibility: visible;
      opacity: 1;
      height: auto;
    }

    99% {
      visibility: hidden;
      opacity: 0;
      height: auto;
    }

    100% {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
  }

  @keyframes noneIn {
    0% {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }

    1% {
      visibility: visible;
      height: auto;
      opacity: 0;
    }

    100% {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
  [data-sparta-container] {
    .sparta-animate {
      &-fade-out,
      &-fade-in {
        animation-iteration-count: 1;
        animation-duration: 1.5s;
        animation-fill-mode: both;
      }
      &-none-out,
      &-none-in {
        animation-iteration-count: 1;
        animation-duration: 0s;
        animation-fill-mode: both;
      }

            &-fade-out {
        animation-name: fadeOut;
      }

      &-fade-in {
        animation-name: fadeIn;
      }
      &-none-out {
        animation-name: noneOut;
      }

      &-none-in {
        animation-name: noneIn;
      }
    }
  }
