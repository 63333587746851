






[data-sparta-container] {

   .roboto-regular, [data-font="roboto-regular"] {
      font-family: $roboto-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;

      strong,
      b {
            font-family: $roboto-bold-family, $roboto-font-fallbacks;
            font-weight: $bold-weight;
            visibility: visible;
      }

      em,
      i {
            font-family: $roboto-italic-family, $roboto-font-fallbacks;
            font-weight: $regular-weight;
            visibility: visible;
      }
   }


   .roboto-italic, [data-font="roboto-italic"] {
      font-family: $roboto-italic-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;
   }


   .roboto-medium, [data-font="roboto-medium"] {
      font-family: $roboto-medium-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;
   }


   .roboto-medium-italic, [data-font="roboto-medium-italic"] {
      font-family: $roboto-medium-italic-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;
   }


   .roboto-bold, [data-font="roboto-bold"] {
      font-family: $roboto-bold-family, $roboto-font-fallbacks;
      font-weight: $bold-weight;
      visibility: visible;
   }


   .roboto-bold-italic, [data-font="roboto-bold-italic"] {
      font-family: $roboto-bold-italic-family, $roboto-font-fallbacks;
      font-weight: $bold-weight;
      visibility: visible;
   }


   .roboto-mono-regular,
   [data-font="roboto-mono-regular"] {
      font-family: $roboto-mono-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;

      strong,
      b {
            font-family: $roboto-mono-bold-family, $roboto-font-fallbacks;
            font-weight: $bold-weight;
            visibility: visible;
      }
   }


   .roboto-mono-medium,
   [data-font="roboto-mono-medium"] {
      font-family: $roboto-mono-medium-family, $roboto-font-fallbacks;
      font-weight: $regular-weight;
      visibility: visible;
   }


   .roboto-mono-bold,
   [data-font="roboto-mono-bold"] {
      font-family: $roboto-mono-bold-family, $roboto-font-fallbacks;
      font-weight: $bold-weight;
      visibility: visible;
   }
}