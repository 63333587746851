


  @mixin grid($columns) {
    @for $i from $columns to 0 {
      $width: $i / $columns * 100;

      &-#{$i} {
        width: $width * 1%;
      }
    }
  }

  @mixin offset($columns) {
    @for $i from $columns through 0 {
      $width: $i / $columns * 100;

      &-offset-#{$i} {
        margin-left: $width * 1% !important;
      }
    }
  }

  @mixin push($columns) {
    @for $i from $columns to 0 {
      $left: $i / $columns * 100;

      &-push-#{$i} {
        position: relative;
        left: $left * 1%;
        right: auto;
      }
    }
  }

  @mixin pull($columns) {
    @for $i from $columns to 0 {
      $right: $i / $columns * 100;

      &-pull-#{$i} {
        position: relative;
        left: auto;
        right: $right * 1%;
      }
    }
  }

  @mixin reset-order() {
    &-reset-order {
      float: left;
      margin-left: 0;
      margin-right: 0;
      left: auto;
      right: auto;
    }
  }

  @mixin block-grid($columns) {
    @for $i from $columns to 0 {
      $width: 100 / $i;

      &-block-grid-#{$i} {
        > li {
          width: $width * 1%;
          list-style: none;

          &:nth-of-type(1n) {
            clear: none;
          }

          &:nth-of-type(#{$i}n + 1) {
            clear: both;
          }
        }
      }
    }
  }

  @mixin uncollapse-columns($breakpoint, $gutter) {
    > .columns,
    > .column {
      padding-left: $gutter;
      padding-right: $gutter;

      @if $breakpoint != 'small' {
        &.#{$breakpoint}-centered {
          float: none;
          margin-left: auto;
          margin-right: auto;

          &:last-child {
            float: none;
          }
        }

        &.#{$breakpoint}-uncentered {
          float: left;
          margin-left: 0;
          margin-right: 0;

          &:last-child {
            float: right;
          }
        }
      }
    }
  }
