



[data-sparta-container] {
  #ada-skip-link.ada-visible-focus {
    display: block;
    font: 14px/18px $spa-font-family;
    height: 20px;
    position: absolute;
    text-align: center;
    transition: top 1s ease-out 0s, background 1s linear 0s;
    width: 200px;
    z-index: 3333;

    &:focus {
      background: #f9f7f4;
      box-shadow: 0px 0px 2px 0px rgba(51, 51, 51, 0.3);
      clip: auto !important;
      clip-path: none;
      color: $spa-link-color;
      left: 0;
      opacity: 1;
      top: 0;
      transition: top 0.1s ease-in 0s, background 0.5s linear 0s;
    }
  }

  a.skipoff {
    display: none;
  }

  .ada-hidden {
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .h-100 {
    height: 100%;
  }

  .spa-oline:focus {
    outline: 1px dotted $bank-dark-gray;
    text-decoration: none;
  }
}
