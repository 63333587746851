






        @import '_config.scss';


        [data-sparta-container] {
          .spa-guillemet {
              &:after,
              &:before {
                  font-size: $guillemet-font-size;
              }

              &:after {
                  content: ' \00BB';
              }

              &--left {
                  &:after {
                      content: '';
                  }

                  &:before {
                      content: '\00AB  '; 
                  }
              }
          }
        }
