@mixin spa-svg-fill($color) {
  &.spa-icon--helper {
    svg {
      fill: $color;
    }
    &.spa-icon--interactive {
      svg {
        fill: $color;
      }
    }
  }
}

  @mixin svg-color-classes {

  .spa-svg-color-gray--default {
    @include spa-svg-fill($color-gray);
  }
  .spa-svg-color-gray-s20--default {
    @include spa-svg-fill($color-gray-s20);
  }
  .spa-svg-color-brand-tertiary--default {
    @include spa-svg-fill($color-brand-tertiary);
  }
  .spa-svg-color-royal-one--default {
    @include spa-svg-fill($color-royal-one);
  }
  .spa-svg-color-regal-one--default {
    @include spa-svg-fill($color-regal-one);
  }



  .spa-svg-color-black--default {
    @include spa-svg-fill($color-black);
  }
  .spa-svg-color-black-alt--default {
    @include spa-svg-fill($color-black-alt);
  }
  .spa-svg-color-gray--default {
    @include spa-svg-fill($color-gray);
  }
  .spa-svg-color-gray-s70--default {
    @include spa-svg-fill($color-gray-s70);
  }
  .spa-svg-color-gray-s40--default {
    @include spa-svg-fill($color-gray-s40);
  }
  .spa-svg-color-gray-s20--default {
    @include spa-svg-fill($color-gray-s20);
  }
  .spa-svg-color-gray-s10--default {
    @include spa-svg-fill($color-gray-s10);
  }
  .spa-svg-color-gray-s05--default {
    @include spa-svg-fill($color-gray-s05);
  }


  .spa-svg-color-royal-two--default {
    @include spa-svg-fill($color-royal-two);
  }
  .spa-svg-color-royal-two-dark--default {
    @include spa-svg-fill($color-royal-two-dark);
  }
  .spa-svg-color-royal-two-dark-2--default {
    @include spa-svg-fill($color-royal-two-dark-2);
  }
  .spa-svg-color-royal-two-s80--default {
    @include spa-svg-fill($color-royal-two-s80);
  }
  .spa-svg-color-royal-two-s50--default {
    @include spa-svg-fill($color-royal-two-s50);
  }
  .spa-svg-color-royal-two-s20--default {
    @include spa-svg-fill($color-royal-two-s20);
  }
  .spa-svg-color-royal-two-s10--default {
    @include spa-svg-fill($color-royal-two-s10);
  }
  .spa-svg-color-royal-two-s05--default {
    @include spa-svg-fill($color-royal-two-s05);
  }
  .spa-svg-color-royal-three--default {
    @include spa-svg-fill($color-royal-three);
  }
  .spa-svg-color-royal-three-dark--default {
    @include spa-svg-fill($color-royal-three-dark);
  }
  .spa-svg-color-royal-three-dark-2--default {
    @include spa-svg-fill($color-royal-three-dark-2);
  }
  .spa-svg-color-royal-three-s80--default {
    @include spa-svg-fill($color-royal-three-s80);
  }
  .spa-svg-color-royal-three-s50--default {
    @include spa-svg-fill($color-royal-three-s50);
  }
  .spa-svg-color-royal-three-s20--default {
    @include spa-svg-fill($color-royal-three-s20);
  }
  .spa-svg-color-royal-three-s10--default {
    @include spa-svg-fill($color-royal-three-s10);
  }
  .spa-svg-color-royal-three-s05--default {
    @include spa-svg-fill($color-royal-three-s05);
  }
  .spa-svg-color-brand-secondary--default {
    @include spa-svg-fill($color-brand-secondary);
  }
  .spa-svg-color-brand-secondary-s80--default {
    @include spa-svg-fill($color-brand-secondary-s80);
  }
  .spa-svg-color-brand-secondary-s50--default {
    @include spa-svg-fill($color-brand-secondary-s50);
  }
  .spa-svg-color-brand-secondary-s20--default {
    @include spa-svg-fill($color-brand-secondary-s20);
  }
  .spa-svg-color-brand-secondary-s10--default {
    @include spa-svg-fill($color-brand-secondary-s10);
  }
  .spa-svg-color-brand-secondary-s05--default {
    @include spa-svg-fill($color-brand-secondary-s05);
  }


  .spa-svg-color-brand-primary--default {
    @include spa-svg-fill($color-brand-primary);
  }
  .spa-svg-color-primary-s80--default {
    @include spa-svg-fill($color-primary-s80);
  }
  .spa-svg-color-primary-s50--default {
    @include spa-svg-fill($color-primary-s50);
  }
  .spa-svg-color-primary-s20--default {
    @include spa-svg-fill($color-primary-s20);
  }
  .spa-svg-color-primary-s10--default {
    @include spa-svg-fill($color-primary-s10);
  }
  .spa-svg-color-primary-s05--default {
    @include spa-svg-fill($color-primary-s05);
  }
  .spa-svg-color-regal-one--default {
    @include spa-svg-fill($color-regal-one);
  }
  .spa-svg-color-regal-one-dark--default {
    @include spa-svg-fill($color-regal-one-dark);
  }
  .spa-svg-color-regal-one-s80--default {
    @include spa-svg-fill($color-regal-one-s80);
  }
  .spa-svg-color-regal-one-s50--default {
    @include spa-svg-fill($color-regal-one-s50);
  }
  .spa-svg-color-regal-one-s20--default {
    @include spa-svg-fill($color-regal-one-s20);
  }
  .spa-svg-color-regal-one-s10--default {
    @include spa-svg-fill($color-regal-one-s10);
  }
  .spa-svg-color-regal-one-s05--default {
    @include spa-svg-fill($color-regal-one-s05);
  }
  .spa-svg-color-regal-two--default {
    @include spa-svg-fill($color-regal-two);
  }
  .spa-svg-color-regal-two-s80--default {
    @include spa-svg-fill($color-regal-two-s80);
  }
  .spa-svg-color-regal-two-s50--default {
    @include spa-svg-fill($color-regal-two-s50);
  }
  .spa-svg-color-regal-two-s20--default {
    @include spa-svg-fill($color-regal-two-s20);
  }
  .spa-svg-color-regal-two-s10--default {
    @include spa-svg-fill($color-regal-two-s10);
  }
  .spa-svg-color-regal-two-s05--default {
    @include spa-svg-fill($color-regal-two-s05);
  }


  .spa-svg-color-secondary-one-dark-2--default {
    @include spa-svg-fill($color-secondary-one-dark-2);
  }


  .spa-svg-color-secondary-three-dark--default {
    @include spa-svg-fill($color-secondary-three-dark);
  }
}