


  [data-sparta-container] {
    [data-equalizer] {
      display: flex;

      > [data-equalizer-watch] {
        flex: 1;
      }
    }
  }
