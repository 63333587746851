@import '_config.scss';
@import '_mixins.scss';

[data-sparta-container] {

  .spa-circle-btn-wrapper .spa-icon {
    width: 24px;
    height: 24px;
    margin: 0;
  }

  .spa-circle-btn-label {
    font-family: $circle-btn-font-family;
    font-size: $btn-label--font-size;
    color: $color-royal-one;
    margin-top: $spacing-8;
    padding-top: 0;
    transition: $spa-standard-transition;
    display: block;
  }

  .spa-circle-btn {
    @include spa-btn--skin($web-button-secondary);

    display: inline-block;
    padding: 0;
    margin: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    position: relative;
    transition: background-color $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;
  }

  .spa-circle-btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spa-circle-btn-wrapper {
    padding: $spacing-8;
    display: inline-block;
    text-align: center;
    background-color: transparent;
    margin-bottom: 0;

    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    &:focus-visible {
      outline: none;
    }

    &:hover .spa-icon,
    &:focus .spa-icon,
    &:active .spa-icon {
      color: $color-brand-secondary;
    }

    &:hover .spa-circle-btn-label,
    &:focus .spa-circle-btn-label,
    &:active .spa-circle-btn-label  {
      color: $bank-medium-blue;
      transition: $spa-standard-transition;
    }

    &:hover .spa-circle-btn-label,
    &:active .spa-circle-btn-label {
      text-decoration: underline;
    }

    &:focus {
      .spa-circle-btn-label {
        text-decoration: none;
      }
      &:hover {
        .spa-circle-btn-label {
          text-decoration: underline;
        }
      }
    } 

    &:hover .spa-circle-btn,
    &:focus .spa-circle-btn,
    &:active .spa-circle-btn {
      transition: background-color;
    }

    &:hover {
      .spa-circle-btn {
        background-color: $color-royal-one-s10;
        border: 1px solid $color-royal-one;
        transition: background-color;
      }
    }

    &:focus {
      .spa-circle-btn {
        background-color: $white;
        border: 3px solid $color-royal-one;
        transition: background-color;
      }
    }

    &:active {
      .spa-circle-btn {
        background-color: $color-royal-one;
        border: none;
        box-shadow: inset 0 4px 0 0 $color-brand-secondary;
      }
    }

    &:hover .spa-circle-btn-icon,
    &:focus .spa-circle-btn-icon,
    &:active .spa-circle-btn-icon,
    &[disabled] .spa-circle-btn-icon {
      transition: background-color;
      border: none;
    }

    &.spa-circle-btn--selected {
      .spa-circle-btn {
        background-color: $color-royal-one;
        border: none;
      }

      &:focus {
        .spa-circle-btn {
          background-color: $color-royal-one;
          box-shadow: inset 0 0 0 2px $white;
          border: 1px solid $color-royal-one;
        }
      }
    }

    &.spa-circle-btn--has-badge {
      position: relative;
      display: inline-block;

      &:hover {
        .spa-badge {

          &--dot {
            margin: 0;
          }

          &--numeric {
            margin: -4px;
          }
        }
      }

      &:focus {
        .spa-badge {
          &--dot {
            margin: -2px;
          }

          &--numeric {
            margin: -6px;
          }
        }
      }

      &:active {
        .spa-badge {
          &--dot {
            margin: 1px;
          }

          &--numeric {
            margin: -3px;
          }
        }
      }

      .spa-badge {
        top: 0;
        right: 0;

        &--numeric {
          margin: -4px;
          padding-top: $spacing-1;
        }
      }
    }

    &[disabled] {
      .spa-circle-btn, 
      .spa-circle-btn-label {
        @include spa-btn--skin($btn-skin--disabled);

        cursor: default;
        pointer-events: none;
        border: none;
        box-shadow: none;
      }

      .spa-circle-btn {
        border-color: $btn-disabled--bg-color;
      }

      .spa-circle-btn-label {
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}